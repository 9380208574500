// WhyChooseUs.js
import React from "react";
import styles from "./ChooseUs.css";

export const ChooseUs = () => {
  const points = [
    "Expertise in leading BI technologies",
    "Customized reporting solutions",
    "Cure and reliable data handling",
    "Dedicated support and training",
    "Scalable solutions for businesses of all sizes",
  ];

  return (
    <div className="why-choose-us">
      <h2>Why Choose Us?</h2>
      <div className="heading-underline"></div>
      <div className="cards">
        {points.map((point, index) => (
          <div key={index} className="card">
            <p>{point}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
