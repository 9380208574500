import React from "react";
import "./Industry.css";

const industries = [
  { name: "Supply Chain", icon: "/Assets/supplychain.png" },
  { name: "Finance", icon: "/Assets/finance.png" },
  { name: "Healthcare", icon: "/Assets/healthcare.png" },
  { name: "Retail & Distribution", icon: "/Assets/retail.png" },
  { name: "Management", icon: "/Assets/management.png" },
  { name: "Edu-Tech", icon: "/Assets/edu.png" },
];

export const Industry = () => {
  return (
    <section className="industries-section" id="industries">
      <div className="industries-container">
        <h2 className="section-title">Industries</h2>
        <div className="heading-underline"></div>
        <div className="industries-grid">
          {industries.map((industry, index) => (
            <div className="industry-card" key={index}>
              <img
                src={industry.icon}
                alt="industryicon"
                className="industryicon"
              />
              <p>{industry.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
