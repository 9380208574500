import React from "react";
import styles from "./Testemonials.css";

const testimonials = [
  {
    name: "John Doe",
    designation: "CEO, Company A",
    comment:
      "This company is fantastic! They provided top-notch service and the results were beyond expectations Incredible experience! Their team is very professional and skilled. Highly recommend!Amazing support and delivery. Our project was a huge success thanks to them ",
  },
  {
    name: "Jane Smith",
    designation: "CTO, Company B",
    comment:
      "Incredible experience! Their team is very professional and skilled. Highly recommend!",
  },
  {
    name: "Alice Johnson",
    designation: "Manager, Company C",
    comment:
      "Amazing support and delivery. Our project was a huge success thanks to them.",
  },
  {
    name: "Bob Brown",
    designation: "Developer, Company D",
    comment:
      "Great collaboration and excellent results. They are our go-to partner for integration solutions.",
  },
  {
    name: "Charlie Green",
    designation: "Engineer, Company E",
    comment:
      "Outstanding service and support. Their solutions have significantly improved our processes.",
  },
];

export const Testimonial = () => {
  return (
    <section className="testimonials-section">
      <div className="testimonials-container">
        <h2 className="section-title">TESTIMONIALS</h2>
        <div className="heading-underline"></div>
        <p className="section-subtitle">What our customers have to say</p>
        <div className="testimonials-slider">
          <div className="testimonials-track">
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-card" key={index}>
                <img
                  className="profile-icon"
                  src="/Assets/usertwo.png"
                  alt=""
                />
                <h3>{testimonial.name}</h3>
                <p className="designation">{testimonial.designation}</p>
                <p className="comment">{testimonial.comment}</p>
              </div>
            ))}
            {/* Duplicate testimonials to create a seamless loop */}
            {/* {testimonials.map((testimonial, index) => (
              <div className="testimonial-card" key={`duplicate-${index}`}>
             
                <img
                  className="profile-icon"
                  src="/Assets/usertwo.png"
                  alt=""
                />
                <h3>{testimonial.name}</h3>
                <p className="designation">{testimonial.designation}</p>
                <p className="comment">{testimonial.comment}</p>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </section>
  );
};
