import React from "react";
import "./AboutSection.css";

export const AboutSection = () => {
  return (
    <section className="about-section" id="about">
      <h2 className="about-heading">About</h2>
      <div className="heading-underline"></div>
      <div className="aboutContainer">
        <div className="aboutLeft">
          <p className="about-text">
            Fusionxcloud is a technology company specializing in developing
            innovative solutions for seamless integration of different software
            systems and platforms. Our team of dedicated engineers and software
            developers work tirelessly to create products that streamline
            processes and optimize workflows for businesses of all sizes.
            <br />
            <br />
            Fusionxcloud is trusted software solution provider in serveral
            Technologies and oracle cloud (SaaS,PaaS,and IaaS )
            <br />
            
          </p>
          <div className="about-icons">
            <div className="icon-box">
              <div className="icon-text">51+</div>
              <div className="icon-description">Happy Clients</div>
            </div>
            <div className="icon-box">
              <div className="icon-text">41+</div>
              <div className="icon-description">Projects</div>
            </div>
            <div className="icon-box">
              <div className="icon-text">5</div>
              <div className="icon-description">Years Exp</div>
            </div>
          </div>
        </div>
        <div className="aboutRight">
          <img
            src="/Assets/aboutimage1.png"
            alt="aboutimage"
            className="aboutrightimage"
          />
        </div>
      </div>
    </section>
  );
};
