import React from "react";
import "./Home.css";

export const Home = () => {
  return (
    <section className="main-section" id="#home">
      <div className="left-part">
        <h1>
        Fusion<span className="span">X</span>cloud
        </h1>
        <p>
          On Intelligence, Integrations, Cloud And Infrastructure Technologies!
        </p>

        <a className="start-button" href="#about">
          Let's Start
        </a>
      </div>
    </section>
  );
};
