// data.js
export const servicesData = [
  {
    id: "service1",
    title: "Business Intelligence & Analytics",
    image: "/Assets/AA1.svg",
    description:
      "We oﬀer a wide range of Business Intelligence solutions to optimize your business performance",
    features: [
      "Oracle BI Publisher",
      "Oracle OBIEE",
      "Oracle Analytics Cloud",
      "Microsoft Power BI",
      "Tableau",
    ],
    description2: "Our BI & Analytics solutions can help you to :",
    features2: [
      "Make better, data-driven decisions",
      "Boost productivity and efficiency",
      "Save money and maximize ROI",
    ],
  },
  {
    id: "service2",
    title: "Integration",
    image: "/Assets/b2.png",
    description:
      "Experience seamless business integration with our expert services! We provide expertise in the following technologies.",
    features: [
      "Oracle Integration Cloud",
      "MuleSoft",
      "Dell Boomi",
      "SAP CPI",
      "APIGEE",
      "Talend",
      "Azure Data Factory",
      "Oracle Data Integrator",
    ],
    description2: "What we offer :",
    features2: [
      " End-to-end integration solutions",
      "Customized process automation",
      "Secure and reliable data transfer",
      "Improved business efficiency and productivity",
      "Scalable solutions for businesses of all sizes",
    ],
  },
  {
    id: "service3",
    title: "Application Development",
    image: "/Assets/A3.svg",
    description: "Experties in Key Technologies",
    features: ["Oracle Apex", "Java Full Stack", "React", "Angular"],
    description2: "Our services include :",
    features2: [
      "Custom application development",
      "Application modernization and migration",
      "Security assessments and hardening",
      "API development and integration",
      "Training and support",
    ],
  },
  {
    id: "service4",
    title: "Enterprise Resource Planning",
    image: "/Assets/A4.svg",
    description: " Software modules that make up the Oracle Cloud ERP suite",
    features: ["Oracle Apex", "Java Full Stack", "React", "Angular"],
    description2: "",
    features2: [],
  },
  {
    id: "service5",
    title: "Infrastructure",
    image: "/Assets/AA5.jpg",
    description: "We provide infrastructure services in :",
    features: ["Oracle Cloud Infrastructure (OCI)",
    "Amazon Web Services (AWS)",
    "Azure",
    "Site Monitoring",
    "Scalability"],
    description2: "A platform of cloud services that enable you to build and run a wide range of applications in a highly-available, consistently high-performance environment.",
    features2: ["Security Features",
    "Content Delivery Network (CDN)",
      "Backup and Recovery"],
  },
  {
    id: "service6",
    title: "Database",
    image: "/Assets/A6.jpg",
    description: "Experience the Future of Database Management.",
    features: [
      "Oracle 11g, 12c and 19c",
      "Oracle ATP/ADW",
      "MongoDB",
      "PostgreSQL",
      "MySQL",
    ],
    description2: "Our services include: ",
    features2: [
      "Database design, installation, and configuration",
      "Performance tuning and optimisation",
      "Security hardening and compliance",
      "High availability and disaster recovery solutons",
      "Data migration and integration",
    ],
  },
  {
    id: "service7",
    title: "Cloud Migration",
    image: "/Assets/A7.svg",
    description:
      "We are a trusted Oracle partner specializing in end-to-end implementation of Oracle Cloud solutions. Our team of experts has extensive experience in SaaS, PaaS, and IaaS, ensuring seamless integration and adoption for your business.",
    features: [
      "Oracle Cloud SaaS - Human Capital Management, Supply Chain Management, Finance, Project Management, Oracle Sales/CX, and Oracle CRM",
      "Oracle Cloud PaaS - Oracle Integration Cloud (ICS, VBCS, and PCS), Apiary for API management, and Oracle ATP/ADW databases",
      "Oracle Cloud IaaS - Oracle Cloud Infrastructure (OCI)",
    ],
    description2: "",
    features2: [],
  },
  // Add more services as needed
];
