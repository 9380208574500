
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { servicesData } from "../Serv/ServicesData";
import "./Header.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = (e) => {
    e.preventDefault(); // Prevent default link navigation
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <img
          className="logo"
          src="/Assets/logomain.png"
          alt="Company Logo"
        />
        <nav className="nav">
          <button className="toggle-button" onClick={toggleMenu}>
            &#9776; {/* Hamburger icon */}
          </button>
          <ul className={`nav-list ${isOpen ? "active" : ""}`}>
            <li className="nav-item">
              <Link
                to="/"
                className="nav-link"
                onClick={() => {
                  closeMenu();
                  window.scrollTo(0, 0);
                }}
              >
                HOME
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/"
                className="nav-link"
                onClick={() => {
                  closeMenu();
                  window.location.hash = "#about";
                }}
              >
                ABOUT
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/"
                className="nav-link"
                onClick={() => {
                  closeMenu();
                  window.location.hash = "#partners";
                }}
              >
                PARTNERS
              </Link>
            </li>
            <li className={`nav-item dropdown ${isDropdownOpen ? "open" : ""}`}>
              {/* Prevent link navigation and toggle dropdown */}
              <a href="/" className="nav-link" onClick={toggleDropdown}>
                SERVICES
              </a>
              <ul className={`dropdown-menu ${isDropdownOpen ? "active" : ""}`}>
                {servicesData.map((service) => (
                  <li key={service.id}>
                    <Link
                      to={`/services/${service.id}`}
                      className="dropdown-link"
                      onClick={closeMenu}
                    >
                      {service.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item">
              <Link
                to="/"
                className="nav-link"
                onClick={() => {
                  closeMenu();
                  window.location.hash = "#industries";
                }}
              >
                INDUSTRIES
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/"
                className="nav-link"
                onClick={() => {
                  closeMenu();
                  window.location.hash = "#contact";
                }}
              >
                CONTACT US
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
