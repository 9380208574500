import React from "react";
import Header from "../../Components/Header/Header";
import { Home } from "../../Components/Home/Home";
import { AboutSection } from "../../Components/About/AboutSection";
import { Clients } from "../../Components/Clients/Clients";
import { Linkbar } from "../../Components/LinkBar/Linkbar";
import { Industry } from "../../Components/Industries/Industry";
import { Testimonial } from "../../Components/Testemonials/Testemonial";
import { Contact } from "../../Components/Contact/Contact";
import { Expertise } from "../../Components/Experties/Experties";

export const Main = () => {
  return (
    <>
      <Header />
      {/* <Linkbar /> */}
      <Home />
      <AboutSection />
      <Expertise />
      <Clients />
      <Industry />
      <Testimonial />
      <Contact />
    </>
  );
};
