import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Main } from "../Main.jsx/Main";
import { Service } from "../../Components/Serv/Service";
import { AboutSection } from "../../Components/About/AboutSection";
import { Industry } from "../../Components/Industries/Industry";
import { Contact } from "../../Components/Contact/Contact";
import { Home } from "../../Components/Home/Home";

export const PageRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />}></Route>
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<AboutSection />} />
      <Route path="/services/:id" element={<Service />} />
      <Route path="/industry" element={<Industry />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
};
