import React from "react";
import styles from "./Client.css";
import { ReactComponent as Oracle } from "../Assets/oracle.png";
import oracle from "../Assets/oracle.png";

const clients = [
  { logo: "/Assets/oracle.png", alt: "Client 1" },
  { logo: "/Assets/cloudare.png", alt: "Client 2" },
  { logo: "/Assets/infosys.png", alt: "Client 3" },
  { logo: "/Assets/microsoft.png", alt: "Client 4" },
  { logo: "/Assets/hindvi.png", alt: "Client 5" },
  { logo: "/Assets/ztranz.png", alt: "Client 6" },
  { logo: "/Assets/tinam.png", alt: "Client 7" },
];

export const Clients = () => {
  return (
    <section className="clients-section" id="partners">
      <div className="clients-container">
        <h2 className="section-title">Our Clients</h2>
        <div className="heading-underline"></div>
        <p className="section-subtitle">Our esteemed clients</p>
        <div className="clients-slider">
          <div className="clients-track">
            {clients.map((client, index) => (
              <div className="client-logo" key={index}>
                <img src={client.logo} alt={client.alt} />
              </div>
            ))}
            {/* Duplicate logos to create a seamless loop */}
            {clients.map((client, index) => (
              <div className="client-logo" key={`duplicate-${index}`}>
                <img src={client.logo} alt={client.alt} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
