import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { servicesData } from "./ServicesData";
import "./Service.css";
import Header from "../Header/Header";
import { ChooseUs } from "./ChooseUs/ChooseUs";
import { Contact } from "../Contact/Contact";
import { Linkbar } from "../LinkBar/Linkbar";

export const Service = () => {
  const { id } = useParams();
  const service = servicesData.find((s) => s.id === id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  if (!service) return <div>Service not found</div>;

  return (
    <>
      <Header />

      <div className="service-page">
        <h2 className="service-heading">{service.title}</h2>
        <div className="heading-underline"></div>
        <div className="service-content">
          <div className="service-image">
            <img src={service.image} alt={service.title} />
          </div>
          <div className="service-details">
            <p>{service.description}</p>
            <ul>
              {service.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>

            <p>{service.description2}</p>
            <ul>
              {service.features2.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ChooseUs />
      <Contact />
    </>
  );
};
