import React, { useState } from "react";
import "./Experties.css";

const expertiseData = {
  oracle: {
    title: "Oracle Expertise",
    description: "We are the Trusted Solution Providers in Oracle Fusion",
    points: [
      "Fusionxcloud help you improve your customer engagements, increase your business’s agility, and react to change faster than ever before.",
      "From enterprise resource planning, supply chain management, and human capital management to advertising and customer experience, our Cloud Experts provides accurate solution to meet every need and requirement of our clients.",
    ],
    image: "/Assets/fusion2.png", // Update with the correct image path
  },
  microsoft: {
    title: "Cloud Services",
    description: "Empowering Business Growth with Microsoft Solutions",
    points: [
      "Fusionxcloud is your trusted partner in harnessing the power of Microsoft technologies, propelling your business towards unparalleled success.",
      "As a dedicated Microsoft Partner, Fusionxcloud brings a wealth of expertise and experience to the table. We collaborate closely with Microsoft to deliver innovative solutions tailored to your unique business needs.",
    ],
    image: "/Assets/365.webp", // Update with the correct image path
  },
};

export const Expertise = () => {
  const [selectedExpertise, setSelectedExpertise] = useState("oracle");

  const handleButtonClick = (expertise) => {
    setSelectedExpertise(expertise);
  };

  const { title, description, points, image } =
    expertiseData[selectedExpertise];

  return (
    <section className="expertise-section">
      <h2 className="expertise-heading">Expertise</h2>
      <div className="heading-underline"></div>

      <div className="expertise-buttons">
        <button
          onClick={() => handleButtonClick("oracle")}
          className={selectedExpertise === "oracle" ? "active" : ""}
        >
          Oracle
        </button>
        <button
          onClick={() => handleButtonClick("microsoft")}
          className={selectedExpertise === "microsoft" ? "active" : ""}
        >
          Cloud Services
        </button>
      </div>
      <div className="expertise-content">
        <div className="expertise-text">
          <h3>{title}</h3>
          <p>{description}</p>
          <ul>
            {points.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </div>
        <div className="expertise-image">
          <img src={image} alt={title} />
        </div>
      </div>
    </section>
  );
};
